@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*=================== fonts ====================*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/

* {
  margin: 0;
  font-family: "Inter", sans-serif;
}
.ck-editor__editable_inline {
  min-height: 300px;
}
.location-search-input {
  border: 2px solid #eb6e13;
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  height: 20px;
}

.App {
  width: 100%;
}
@media (max-width: 600px) {
  .App {
    width: 100%;
  }
}


#editableInput {
  border: 10px !important;
  font-size: 20px;
  border-color: black;
}


.ql-container.ql-snow
{
    font-size: 14px;
    color: black;
    text-align: justify;
}

.my-editing-area
{
  margin: 400;
  border: none !important;
}



