
#editableInput {
  border: 10px !important;
  font-size: 20px;
  border-color: black;
}


.ql-container.ql-snow
{
    font-size: 14px;
    color: black;
    text-align: justify;
}

.my-editing-area
{
  margin: 400;
  border: none !important;
}


