/*=================== fonts ====================*/
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
/*---------------------------------------------------- */
/*----------------------------------------------------*/

* {
  margin: 0;
  font-family: "Inter", sans-serif;
}
.ck-editor__editable_inline {
  min-height: 300px;
}
.location-search-input {
  border: 2px solid #eb6e13;
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  height: 20px;
}

.App {
  width: 100%;
}
@media (max-width: 600px) {
  .App {
    width: 100%;
  }
}
